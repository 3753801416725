<template>
    <div class="component-container">
        <div class="content-categories-container">
            <div v-for="category of displayCategories" class="content-category"
                :style="{ borderColor: category.attributes.color, color: category.attributes.color }">
                {{ localize(category.attributes.name) }}
            </div>
        </div>
    </div>
</template>

<script>
import { contentCategoriesQuery } from '@/queries'
export default {
    name: 'ContentCategories',

    props: [
        'articleEntity',
        'categories'
    ],

    apollo: {
        contentCategories: {
            query: contentCategoriesQuery,
            variables() {
                return {
                    articleEntity: this.articleEntity
                }
            },
            // Skip the query if the categories are already provided via prop
            skip() {
                return this.categories !== undefined;
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
        },
    },

    computed: {
        displayCategories() {
            return (this.contentCategories?.data ?? this.categories) ?? null
        },
    }
}
</script>

<style lang="scss" scoped>
.component-container {

    .content-categories-container {
        display: flex;
        gap: 10px;

        .content-category {
            border: 2px solid #000;
            padding: 2px 5px;
        }
    }
}
</style>
